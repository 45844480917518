import CTALink from "@components/elements/CTALink";
import GGFXImage from "@components/elements/GGFXImage";
import Slider from "@components/elements/Slider/Slider";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { parseHTML, scrollToID } from "@lib/utils";
import { graphql, useStaticQuery } from "gatsby";
import CloseIcon from "@components/icons/CloseIcon";
import { ResponsiveContainer, StackedCarousel } from "react-stacked-center-carousel";
import CustomSlide from "@components/TitleBlock/CustomSlide";
import VideoBanner from "@components/VideoBanner/VideoBanner";
import "./Events.scss";

const EventsItem = ({ service, status, googleCalendarUrl, setTargetName }) => {
  const { cta, title, ggfx_results, description, strapi_id, bg_color, location, start_date, end_date, images, popup_video } = service;

  const hasCta = !!cta?.custom_link || !!cta?.menu;
  const Comp = hasCta ? CTALink : "div";
const carouselRef = useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = useState(0);

  const handlePrevSlide = () => {
    carouselRef.current.goBack(); // Move to the previous slide
  };

  const handleNextSlide = () => {
    carouselRef.current.goNext(); // Move to the next slide
  };

  const handleActiveSlideChange = (newCenterDataIndex) => {
    // Set the new center slide index
    setCenterSlideDataIndex(newCenterDataIndex);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let galleryImg = [];
  if (images && Array.isArray(images)) {
    galleryImg = images.map((item) => ({
      image: { url: item?.image?.url },
    }));
  }
  return (
    <div className={`event-item ${bg_color}`}>
      <Comp  onClick={handleShow} cta={cta} className={clsx("img-section", hasCta && "img-zoom")}>
        <GGFXImage
          ImageSrc={images[0]?.image}
          altText={title}
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="event.images.image"
          strapiID={strapi_id}
        />
        <div className="status">{status}</div>
      </Comp>
      <div className="content-section">
        <div className="event-name" onClick={handleShow}>{title}</div>
        <div className="event-loc"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M10 7C10 8.10457 9.10457 9 8 9C6.89543 9 6 8.10457 6 7C6 5.89543 6.89543 5 8 5C9.10457 5 10 5.89543 10 7Z" stroke="#9399A4" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13 7C13 11.7614 8 14.5 8 14.5C8 14.5 3 11.7614 3 7C3 4.23858 5.23858 2 8 2C10.7614 2 13 4.23858 13 7Z" stroke="#9399A4" stroke-linecap="round" stroke-linejoin="round"/>
</svg> {location}</div>
        <div className="event-date">Date: <b>{start_date} {end_date ? "-" : ""} {end_date}</b></div>
        {/* {hasCta ? (
          <CTALink cta={cta} className="title" />
        ) : (
          <p className="title">{cta?.cta_label}</p>
        )}
        <p className="description">{parseHTML(description?.data?.description)}</p> */}
        <div className="btns">
        <button  onClick={() => {
                                  scrollToID("Event_Form");
                                  setTargetName(title)
                                }}
                                  className="reg-btn button button-orange">
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M5.83398 5.33341V4.66675M5.83398 8.33341V7.66675M5.83398 11.3334V10.6667M5.03398 13.3334H11.9673C13.0874 13.3334 13.6475 13.3334 14.0753 13.1154C14.4516 12.9237 14.7576 12.6177 14.9493 12.2414C15.1673 11.8136 15.1673 11.2535 15.1673 10.1334V5.86675C15.1673 4.74664 15.1673 4.18659 14.9493 3.75877C14.7576 3.38244 14.4516 3.07648 14.0753 2.88473C13.6475 2.66675 13.0874 2.66675 11.9673 2.66675H5.03398C3.91388 2.66675 3.35383 2.66675 2.926 2.88473C2.54968 3.07648 2.24372 3.38244 2.05197 3.75877C1.83398 4.18659 1.83398 4.74664 1.83398 5.86675V10.1334C1.83398 11.2535 1.83398 11.8136 2.05197 12.2414C2.24372 12.6177 2.54968 12.9237 2.926 13.1154C3.35383 13.3334 3.91388 13.3334 5.03398 13.3334Z" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          Register Now
          </button>
          <a href={googleCalendarUrl}
      target="_blank"
      rel="noopener noreferrer" className="calendar-btn button button-gray">
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
  <path d="M14.5 6.66659H2.5M11.1667 1.33325V3.99992M5.83333 1.33325V3.99992M5.7 14.6666H11.3C12.4201 14.6666 12.9802 14.6666 13.408 14.4486C13.7843 14.2569 14.0903 13.9509 14.282 13.5746C14.5 13.1467 14.5 12.5867 14.5 11.4666V5.86659C14.5 4.74648 14.5 4.18643 14.282 3.7586C14.0903 3.38228 13.7843 3.07632 13.408 2.88457C12.9802 2.66659 12.4201 2.66659 11.3 2.66659H5.7C4.5799 2.66659 4.01984 2.66659 3.59202 2.88457C3.21569 3.07632 2.90973 3.38228 2.71799 3.7586C2.5 4.18643 2.5 4.74648 2.5 5.86659V11.4666C2.5 12.5867 2.5 13.1467 2.71799 13.5746C2.90973 13.9509 3.21569 14.2569 3.59202 14.4486C4.01984 14.6666 4.5799 14.6666 5.7 14.6666Z" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          Add to Calendar
          </a>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        scrollable={false}
        backdrop="static"
        className="event-modal-wrap"
      >
        <Container>
        <div className="event-modal"> 
          <span className="modal-close"><CloseIcon color="white" onClick={handleClose} /></span>
          <div className="content-section">
            <h3 className="title">{title}</h3>
            <p className="description">{parseHTML(description?.data?.description)}</p>
          </div>
          <div className="slider-container" style={{ width: "100%", position: "relative" }}>
            <ResponsiveContainer
              carouselRef={carouselRef}
              render={(parentWidth, carouselRef) => {
                let currentVisibleSlide = 5;
                if (parentWidth <= 1024) currentVisibleSlide = 5;
                else if (parentWidth <= 767) currentVisibleSlide = 1;
                return (
                  <StackedCarousel
                    ref={carouselRef}
                    slideComponent={(slideProps) => (
                      <CustomSlide
                        {...slideProps}
                        strapiID={strapi_id}
                        ggfx_results={ggfx_results}
                        imagename= "event.images.image_slide"
                        onActiveSlideChange={handleActiveSlideChange}
                      />
                    )}
                    height={parentWidth < 768 ? 336 : 430}
                    slideWidth={parentWidth < 768 ? 280 : 360}
                    carouselWidth={parentWidth}
                    data={galleryImg}
                    maxVisibleSlide={currentVisibleSlide}
                    fadeDistance={0} 
                    disableSwipe={parentWidth <= 767 ? false : true}
                    swipeSpeed={parentWidth < 768 ? 0.5 : 0.1}
                    currentVisibleSlide={currentVisibleSlide}
                    onActiveSlideChange={handleActiveSlideChange}
                    initialActiveSlideIndex={centerSlideDataIndex}
                    transitionTime={600}
                  />
                );
              }}
            />
            {/* Arrow buttons */}
            <div className="controls">
              <button className="custom-arrow prev" onClick={handlePrevSlide}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="arrow-left-icon"><path d="M15.75 19.5L8.25 12L15.75 4.5" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </button>
              <button className="custom-arrow next" onClick={handleNextSlide}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="arrow-right-icon"><path d="M8.25 4.5L15.75 12L8.25 19.5" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </button>
            </div>
          </div>
          {popup_video && (
            <VideoBanner
              bannerVideo={popup_video}
              ggfx_results={ggfx_results}
              strapiID={strapi_id}
              imagename={"event.thumbnail.banner_video_thumbnail"}
            />
          )}
        </div>
        </Container>
      </Modal>
    </div>
  );
};

const Events = ({ module, setTargetName }) => {
  if (!module) return null;
  const { title, heading, design_title } = module;

  const data = useStaticQuery(graphql`
    query {
      allStrapiEvent(filter: { publish: { eq: true } }) {
         edges {
          node {
            title
            description {
              data {
                description
              }
            }
            images {
              image {
                url
              }
            }
            ggfx_results {
                src_cftle
              transforms {
                url
                transform
                format
              }
            }
            popup_video {
              id
              strapi_id
              thumbnail {
                url
              }
              video {
                url
              }
              video_url
            }
            location
            start_date(formatString: "MMM DD, YYYY")
            end_date(formatString: "MMM DD, YYYY")
            publish
          }
        }
      }
    }
  `);

  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  const events = data.allStrapiEvent.edges
    .filter(({ node }) => {
      const startDate = new Date(node.start_date);
      const endDate = new Date(node.end_date);
      const todayDate = new Date(today);
  
      return startDate >= todayDate || endDate >= todayDate; // Filter for today & future events
    })
    .sort((a, b) => new Date(a.node.start_date) - new Date(b.node.start_date)); // Sort by start_date ascending (earliest first)
    const formatDateForGoogleCalendar = (dateString) => {
      const date = new Date(dateString);
      return date.toISOString().replace(/[-:]/g, "").split(".")[0] + "Z"; // Convert to YYYYMMDDTHHmmssZ format
    };
  return (
    <div className="event-wrap" id="events">
      <Container className="event-container">
        <h2 className="title">{heading}</h2>
        {design_title && (
          <div className="design_title">
            {parseHTML(design_title?.data?.design_title)}
          </div>
        )}
        <div className="event-section">
          <Slider className="event-slider" size="sm">
            {events.map(({ node }) => {
              const startDate = new Date(node.start_date);
              const endDate = new Date(node.end_date);
              const todayDate = new Date(today);

              let status = "";
          
              if (startDate > todayDate) {
                const daysRemaining = Math.ceil((startDate - todayDate) / (1000 * 60 * 60 * 24));
                status = `Starts in ${daysRemaining} ${daysRemaining === 1 ? "Day" : "Days"}`;
              } else if (startDate <= todayDate && endDate >= todayDate) {
                status = "Ongoing";
              }
              const googleCalendarUrl = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(node.title)}&dates=${formatDateForGoogleCalendar(node.start_date)}/${formatDateForGoogleCalendar(node.end_date)}&details=${encodeURIComponent(node.description?.data?.description || "")}&location=${encodeURIComponent(node.location || "")}`;
              return(
              <EventsItem
                service={node}
                key={node.id}
                status={status}
                googleCalendarUrl={googleCalendarUrl}
                setTargetName={setTargetName}
              />)
})}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default Events;
