import React, { useRef, useState } from "react"
import "./InteriorProjectCard.scss"
import Animation from "@components/elements/Animation"
import GGFXImage from "@components/elements/GGFXImage"
import { Col, Container, Modal, Row } from "react-bootstrap"
import CloseIcon from "@components/icons/CloseIcon"
import VideoBanner from "@components/VideoBanner/VideoBanner"
import { ResponsiveContainer, StackedCarousel } from "react-stacked-center-carousel";
import CustomSlide from "@components/TitleBlock/CustomSlide"

const InteriorProjectCard = (props) => {
  const carouselRef = useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = useState(0);

  const handlePrevSlide = () => {
    carouselRef.current.goBack(); // Move to the previous slide
  };

  const handleNextSlide = () => {
    carouselRef.current.goNext(); // Move to the next slide
  };

  const handleActiveSlideChange = (newCenterDataIndex) => {
    // Set the new center slide index
    setCenterSlideDataIndex(newCenterDataIndex);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let galleryImg = [];
  if (props?.gallery && Array.isArray(props.gallery)) {
    galleryImg = props.gallery.map(item => ({
      image: { url: item.url },
    }));
  }
  return (
    <Animation
      animateInType="up"
      className="ip-card"
      wrapperClassName="ip-card-wrapper"
    >
      <div className="img-section-wrap img-zoom" onClick={handleShow}>
        <GGFXImage
          ImageSrc={props.image}
          altText={props.title}
          imagetransforms={props.ggfx_results}
          renderer="srcSet"
          imagename="interior-project.image.tile_image"
          strapiID={props.strapi_id}
        />
      </div>
      <h2 className="title" onClick={handleShow}>{props.title}</h2>
      <Modal
        show={show}
        onHide={handleClose}
        scrollable={false}
        backdrop="static"
        className="interior-project-modal-wrap"
      >
        <Container>
        <div className="interior-project-modal"> 
          <span className="modal-close"><CloseIcon color="white" onClick={handleClose} /></span>
          <div className="content-section">
            <h3 className="title">{props.title}</h3>
            <p className="description">{props.short_description}</p>
          </div>
          <div className="slider-container" style={{ width: "100%", position: "relative" }}>
            <ResponsiveContainer
              carouselRef={carouselRef}
              render={(parentWidth, carouselRef) => {
                let currentVisibleSlide = 5;
                if (parentWidth <= 1024) currentVisibleSlide = 5;
                else if (parentWidth <= 767) currentVisibleSlide = 1;
                return (
                  <StackedCarousel
                    ref={carouselRef}
                    slideComponent={(slideProps) => (
                      <CustomSlide
                        {...slideProps}
                        strapiID={props.strapi_id}
                        ggfx_results={props.ggfx_results}
                        imagename= "interior-project.image.gallery"
                        onActiveSlideChange={handleActiveSlideChange}
                      />
                    )}
                    height={parentWidth < 768 ? 336 : 430}
                    slideWidth={parentWidth < 768 ? 280 : 360}
                    carouselWidth={parentWidth}
                    data={galleryImg}
                    maxVisibleSlide={currentVisibleSlide}
                    fadeDistance={0} 
                    disableSwipe={parentWidth <= 767 ? false : true}
                    swipeSpeed={parentWidth < 768 ? 0.5 : 0.1}
                    currentVisibleSlide={currentVisibleSlide}
                    onActiveSlideChange={handleActiveSlideChange}
                    initialActiveSlideIndex={centerSlideDataIndex}
                    transitionTime={600}
                  />
                );
              }}
            />
            {/* Arrow buttons */}
            <div className="controls">
              <button className="custom-arrow prev" onClick={handlePrevSlide}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="arrow-left-icon"><path d="M15.75 19.5L8.25 12L15.75 4.5" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </button>
              <button className="custom-arrow next" onClick={handleNextSlide}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="arrow-right-icon"><path d="M8.25 4.5L15.75 12L8.25 19.5" stroke="#07234B" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </button>
            </div>
          </div>
          {props.popup_video && (
            <VideoBanner
              bannerVideo={props.popup_video}
              ggfx_results={props.ggfx_results}
              strapiID={props.strapi_id}
            />
          )}
        </div>
        </Container>
      </Modal>
    </Animation>
  )
}

export default InteriorProjectCard
