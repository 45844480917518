import { useEffect, useState } from "react"

const useLoadMore = ({ allItems = [], itemsPerPage = 10 }) => {
  const [itemsToShow, setItemsToShow] = useState(
    allItems.slice(0, itemsPerPage)
  )
  const [hasMore, setHasMore] = useState(itemsToShow.length < allItems.length)

  useEffect(() => {
    setItemsToShow(allItems.slice(0, itemsPerPage))
    setHasMore(itemsPerPage < allItems.length)
  }, [itemsPerPage, allItems])

  const loadMore = () => {
    const nextItems = allItems.slice(
      itemsToShow.length,
      itemsToShow.length + itemsPerPage
    )
    setItemsToShow([...itemsToShow, ...nextItems])
    setHasMore(itemsToShow.length + nextItems.length < allItems.length)
  }

  return { itemsToShow, hasMore, loadMore }
}

export default useLoadMore
