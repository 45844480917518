import Animation from "@components/elements/Animation"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./ReviewBanner.scss"
import Star from "@components/icons/Star"
import googleReview from "@images/google-with-stars.svg"
import trustpilotReview from "@images/trustpilot.svg"
import GGFXImage from "@components/elements/GGFXImage"

const ReviewBanner = ({ module, ggfx_results, strapiID }) => {
  const { review, name, image, date } = module


  return (
    <Animation
      className=""
      wrapperClassName={clsx("review-banner-wrap section-m")}
    >
      <Container className="review-banner-container">
        <div className="banner-section">
          <div className={image?.url ? "bg-img" : "bg-img no-bg"}>
            {/* <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={image?.url} alt={review} /> */}
            <GGFXImage
              ImageSrc={image}
              altText={review}
              imagetransforms={ggfx_results}
              renderer="srcSet"
              imagename="page.image.review_img"
              strapiID={strapiID}
            />
          </div>
          <div className="content-section">
            <div className="icons-wrap">
              <Star />
              <Star />
              <Star />
              <Star />
              <Star />
            </div>
            <p className="review">{review}</p>
            <p className="name">{name}</p>
            <p className="date">{date}</p>
            <div className="review-logo-section">
              <div className="review-section google-review">
                <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={googleReview} alt="google review" />
                <p className="review-count">4.8</p>
              </div>
              <div className="divider" />
              <div className="review-section trustpilot-review">
                <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={trustpilotReview} alt="trustpilot review" />
                <p className="review-count">4.9/5</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Animation>
  )
}

export default ReviewBanner
