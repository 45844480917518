import { capitalize, slugToTitle, sort } from "@lib/utils"
import { graphql, useStaticQuery } from "gatsby"

const useLimitReadyOffplans = (type) => {
  const data = useStaticQuery(graphql`
    query {
      ready: allProjects(
        limit: 6
        filter: { publish: { eq: true }, building_type: { eq: "branded" }, status: { eq: "ready" } }
        sort: { fields: updatedAt, order: DESC }
      ) {
        nodes {
          ...offplanCardFragment
        }
      }
      latest: allProjects(
        limit: 6
        filter: { publish: { eq: true }, building_type: { eq: "branded" } }
        sort: { fields: updatedAt, order: DESC }
      ) {
        nodes {
          ...offplanCardFragment
        }
      }
    }
  `)

  const offplans = type === "ready-projects" ? data?.ready?.nodes : data?.latest?.nodes
  offplans.sort((a, b) => sort(a?.rank, b?.rank))

  const developers = [
    ...new Set(offplans?.map((offplan) => offplan?.developer)),
  ]
  const developerOptions = [
    {
      label: "All Developers",
      value: "",
    },
    ...developers.map((developer) => ({
      label: developer,
      value: developer?.toLowerCase().replace(/\s/g, "-"),
    })),
  ]

  const buildingTypes = []
  offplans?.forEach((offplan) => {
    offplan?.building_type?.forEach((buildingType) => {
      if (!buildingTypes.includes(buildingType))
        buildingTypes.push(buildingType)
    })
  })

  const buildingTypeOptions = [
    {
      label: "All Types",
      value: "",
    },
    ...buildingTypes.map((buildingType) => ({
      label: capitalize(slugToTitle(buildingType)),
      value: buildingType.toLowerCase().replace(/\s/g, "-"),
    })),
  ]

  return { offplans, developerOptions, buildingTypeOptions }
}

export default useLimitReadyOffplans
