import React from "react"
import "./ImageBanner.scss"
import { Container, Row, Col } from "react-bootstrap"
import GGFXImage from "@components/elements/GGFXImage"

const ImageBanner = ({ module, ggfx_results, strapiID }) => {
  if (!module?.image) return null

  const { title, image } = module

  let padding = ""
  if (module?.spacing === "none") {
    padding = "m-0"
  } else if (module?.spacing === "top_none") {
    padding = "mt-0"
  } else if (module?.spacing === "bottom_none") {
    padding = "mb-0"
  }

  return (
    <div className="image-banner-wrap section-m">
      <div className="banner-image">
        <GGFXImage
          ImageSrc={image}
          altText="banner-img"
          imagetransforms={ggfx_results}
          renderer="srcSet"
          imagename="page.image_banner_image.image"
          strapiID={strapiID}
        />
      </div>
      {title &&
        <Container>
          <Row>
            <Col md={6}>
              <h2>{title}</h2>
            </Col>
          </Row>
        </Container>
      }
      
    </div>
  )
}

export default ImageBanner
