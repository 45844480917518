import OffplanCard from "@components/OffplanCard/OffplanCard"
import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/Slider"
import useProjects from "@hooks/dataHooks/useProjects"
import React, { useEffect, useMemo, useState, useRef } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "./ProjectsList.scss"
import { getOrderedImages } from "@lib/utils"
import useLoadMore from "@hooks/useLoadMore"
const ProjectsList = ({ module }) => {
  const projects = useProjects()
  const { itemsToShow, hasMore, loadMore } = useLoadMore({
    allItems: projects,
    itemsPerPage: 12,
  });
  const loadMoreRef = useRef(null);
  useEffect(() => {
    if (!hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { rootMargin: "100px" } // Trigger before fully in view
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasMore, loadMore]);
  if (!module) return null
  const { title } = module

  let padding = ""
  if (module?.spacing === "none") {
    padding = "m-0"
  } else if (module?.spacing === "top_none") {
    padding = "mt-0"
  } else if (module?.spacing === "bottom_none") {
    padding = "mb-0"
  }

  return (
    <section className={`projects-listing-wrap section-m ${padding}`}>
      <Container>
        <Row>
          <Col>
            <div className="content-section">
              <div className="d-flex justify-content-between">
                <h2 className="title">
                  <span>{title}</span>
                </h2>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="row-gap-4">
          {itemsToShow.map((offplan) => {
            const imagesArray = getOrderedImages(offplan);
            return(
            <Col md={6} xl={4}>
              <OffplanCard
                minsBeds={offplan.min_bedrooms}
                maxBeds={offplan.max_bedrooms}
                displayBedrooms={offplan.display_bedrooms}
                buldingType={offplan.building_type}
                image={offplan.tile_image ? offplan.tile_image : offplan?.media_images?.length > 0 ? offplan?.media_images[0] : offplan?.images?.length > 0 ? offplan?.images[0] : ""}
                images2={imagesArray}
                title={offplan.title}
                status={offplan.status}
                developer={offplan.developer}
                location={offplan.display_address}
                slug={offplan.slug}
                videoURL={offplan?.video_module?.video_url}
                community={offplan?.community?.title}
                key={offplan.id}
                completionYear={offplan.completion_year}
                price={offplan?.price}
                slider
                future_launch={offplan?.future_launch}
                ggfx_results={offplan?.ggfx_results}
                strapi_id={offplan?.strapi_id}
                notalgo
                property={offplan}
              />
            </Col>
            )
          })}
          <Col>
          {hasMore && (
            <Col>
              <div className="d-flex justify-content-center" ref={loadMoreRef}>
                <button className="button button-white" onClick={loadMore}>
                  Load More
                </button>
              </div>
            </Col>
          )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ProjectsList
