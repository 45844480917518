import InteriorProjectCard from "@components/InteriorProjectCard/InteriorProjectCard"
import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/Slider"
import useLimitInteriorProject from "@hooks/dataHooks/useLimitInteriorProject"
import React, { useEffect, useMemo, useState } from "react"
import { Container } from "react-bootstrap"
import "./InteriorProjectsSlider.scss"
import { getOrderedImages } from "@lib/utils"
const InteriorProjectsSlider = ({ module }) => {
  const IPToShow = useLimitInteriorProject()

  if (!module) return null
  const { title } = module

  return (
    <Animation
      animateInType="up"
      className={`interior-projects-slider-module-wrap section-p`}
    >
      <Container className="interior-projects-slider-module-container">
        <div className="content-section">
          <div className="d-flex justify-content-between">
            <div>
              <h2 className="title"><span>{title}</span></h2>
              <p>{module.short_description}</p> 
            </div>
          </div>
        </div>
        <div className="interior-projects-slider-tab-section">
          <div className="tab-body">
            <Slider size="md">
              {IPToShow
              .slice(0, 8)
              .map((InteriorProject) => {
                return(
                  <InteriorProjectCard
                  image={InteriorProject.tile_image ? InteriorProject.tile_image : InteriorProject?.gallery?.length > 0 ? InteriorProject?.gallery[0] : ""}
                  gallery={InteriorProject.gallery}
                  title={InteriorProject.title}
                  short_description={InteriorProject.short_description}
                  popup_video={InteriorProject.popup_video}
                  ggfx_results={InteriorProject.ggfx_results}
                  strapi_id={InteriorProject.strapi_id}
                />
                )
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </Animation>
  )
}

export default InteriorProjectsSlider
