import { graphql, useStaticQuery } from "gatsby"

const useLimitInteriorProject = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiInteriorProject(
        filter: { publish: { eq: true } }
        sort: { fields: updatedAt, order: DESC }
        limit: 8
      ) {
        nodes {
          ...interiorProjectFragment
        }
      }
    }
  `)

  return data?.allStrapiInteriorProject?.nodes
}

export default useLimitInteriorProject
