import loadable from "@loadable/component"
import AdsBanner from "@components/AdsBanner/AdsBanner"
import AutoPopup from "@components/AutoPopup/AutoPopup"
import BannerLanding from "@components/Banner/BannerLanding"
import CareerListing from "@components/CareerListing/CareerListing"
import FormModule from "@components/ContactFomModule/FormModule"
import ContentAndStats from "@components/ContentAndStats/ContentAndStats"
import FAQ from "@components/FAQ/FAQ"
import FeaturedPropertiesSlider from "@components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
import IFXCalculator from "@components/IFXCalculator/IFXCalculator"
import IconCardsModule from "@components/IconCardsModule/IconCardsModule"
import ImagesSlider from "@components/ImagesSlider/ImagesSlider"
import ImagesSliderModule from "@components/ImagesSliderModule/ImagesSliderModule"
import Layout from "@components/Layout/Layout"
import NewsSlider from "@components/NewsSlider/NewsSlider"
import OurServices from "@components/OurServices/OurServices"
import { MortgageCalculator } from "@components/PropertyMortgageCalc/PropertyMortgageCalc"
import QuoteModule from "@components/QuoteModule/QuoteModule"
import ReviewBanner from "@components/ReviewBanner/ReviewBanner"
import StaffThoughtBanner from "@components/StaffThoughtBanner/StaffThoughtBanner"
import TextCopy from "@components/TextCopy/TextCopy"
import TileBlock from "@components/TileBlock/TileBlock"
import TimelineNumbered from "@components/Timeline/TimelineNumbered"
import { VideoBannerModule } from "@components/VideoBanner/VideoBanner"
import ProjectsSlider from "@components/ProjectsSlider/ProjectsSlider"
import InteriorProjectsSlider from "@components/InteriorProjectsSlider/InteriorProjectsSlider"
import Seo from "@components/seo"
import "@styles/main.scss"
import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import ReviewSlider from "../components/ReviewSlider/ReviewSlider"
import OurPartner from "@components/OurPartner/OurPartner"
import QuestionnaireBanner from "@components/QuestionnaireBanner/QuestionnaireBanner"
import ServicesGrid from "@components/ServicesGrid/ServicesGrid"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import AwardsTimeline from "@components/AwardsTimeline/AwardsTimeline"
import SignaturePropertiesSliderLatest from "@components/SignaturePropertiesSlider/SignaturePropertiesSliderLatest"
import BannerMenu from "@components/Banner/BannerMenu"
import ProjectsList from "@components/ProjectsList/ProjectsList"
import ImageBanner from "@components/ImageBanner/ImageBanner"
// import Process from "@components/Process/Process"
import Events from "@components/Events/Events"
import OpenHousePropertiesSlider from "@components/FeaturedPropertiesSlider/OpenHousePropertiesSlider"
const Process = loadable(() => import("@components/Process/Process"))
const VideoAskWidget = loadable(() => import("@components/VideoAsk/VideoAsk"))
const MasterStaticLanding = ({ data }) => {
  const pageData = data?.strapiPage
  const menuData = pageData?.menu
  const modules = pageData?.modules
  const banner = pageData?.banner
  const imagetransforms = pageData?.ggfx_results
  const strapiID = pageData?.strapi_id
  const team = pageData?.team_contact
  
  const path = (typeof window !== "undefined" && window.location.pathname)
  useEffect(() => {
    if (sessionStorage.getItem("searchpath") && typeof window !== "undefined") {
      sessionStorage.removeItem("searchpath");
    }
    
  }, []);
  const [targetName, setTargetName] = useState('')
  return (
    <Layout pageData={pageData} hideNewsletter={pageData?.page_name === "Careers"}>
      {banner ?
      <BannerLanding
        bannerData={banner}
        ggfx_results={pageData?.ggfx_results}
        strapiID={strapiID}
        menuData={menuData}
        team={team}
      />: 
      <>
      <BannerMenu color="black" />
      <Breadcrumbs classn={modules && modules?.length > 0 && modules[0]?.bg_color} menuData={menuData} />
      </>}
      <AutoPopup />
      {/* {pageData?.video_ask_url &&
<VideoAskWidget video_ask_url={pageData?.video_ask_url} />
} */}
      {modules?.map((module) => (
        <React.Fragment key={module.id}>
          {module?.strapi_component === "modules.ads-banner" && (
            <AdsBanner module={module} />
          )}
          {module?.strapi_component === "modules.ifx-calculator" && (
            <IFXCalculator module={module} />
          )}
          {module?.strapi_component === "modules.year-award" && (
            <AwardsTimeline imagetransforms={imagetransforms}
            strapiID={strapiID} module={module} />
          )}
          {module?.strapi_component === "modules.featured-prop-slider" && (
            pageData?.page_class === "signature-page" ?
              <SignaturePropertiesSliderLatest module={module} />
              :
            <FeaturedPropertiesSlider module={module} />
          )}
          {module?.strapi_component === "modules.timeline" &&
            module?.numbered_timeline && <TimelineNumbered module={module} />}
          {module?.strapi_component === "modules.content-and-stats" && (
            <ContentAndStats module={module} />
          )}
          {module?.strapi_component === "modules.faq" && (
            <FAQ module={module} />
          )}
          {module?.strapi_component === "modules.form-module" && (
            <FormModule module={module} targetName={targetName}/>
          )}
          {module?.strapi_component === "modules.career-listing" && (
            <CareerListing module={module} />
          )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "news_slider" && (
              <NewsSlider module={module} />
            )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "reviews_slider" && (
              <ReviewSlider module={module} reviews={pageData?.reviews} />
            )}
            {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "process_step" && (
              <Process module={module}/>
            )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "mortgage_calculator" && (
              <MortgageCalculator module={module} />
            )}
             {module?.strapi_component === "modules.our-services" && module?.is_grid && (
            <ServicesGrid
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "event_slider" && (
              <Events module={module} setTargetName={setTargetName}/>
            )}
             {module?.strapi_component === "modules.global-module" &&
            module.choose_module === "open_house_slider" && (
              <OpenHousePropertiesSlider module={module} setTargetName={setTargetName}/>
            )}
          {module?.strapi_component === "modules.our-services" && !module?.is_grid &&(
            <OurServices
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "modules.review-banner" && (
            <ReviewBanner
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "modules.tile-block" && (
            <TileBlock
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
              btnColor={pageData?.page_class === "signature-page"}
            />
          )}
          {module?.strapi_component === "modules.images-slider" && (
            <ImagesSlider
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
           {module?.strapi_component === "modules.partner" && (
            <>
            <OurPartner
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
            </>)}
           {module?.strapi_component === "modules.questionnaire" && (
          <QuestionnaireBanner  ggfx_results={pageData?.ggfx_results}
          strapiID={strapiID} bannerData={module} />
          )}
          {module?.strapi_component === "modules.staff-thought" && (
            <StaffThoughtBanner
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "components.text-rich-text" && (
            <TextCopy
              content={module?.description?.data?.description}
              title={module?.title}
            />
          )}
          {module?.strapi_component === "modules.icon-cards" && (
            <IconCardsModule module={module} />
          )}
          {module?.strapi_component === "modules.quote" && (
            <QuoteModule module={module} />
          )}
          {module?.strapi_component === "modules.image-slider" && (
            <ImagesSliderModule module={module} ggfx_results={pageData?.ggfx_results}
            strapiID={strapiID} />
          )}
          {module?.strapi_component === "components.video-block" && (
            <VideoBannerModule
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "components.projects-slider" && (
            <ProjectsSlider
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "components.projects-listing" && (
            <ProjectsList
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "components.image-banner" && (
            <ImageBanner
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
          {module?.strapi_component === "components.interior-project" && (
            <InteriorProjectsSlider
              module={module}
              ggfx_results={pageData?.ggfx_results}
              strapiID={strapiID}
            />
          )}
        </React.Fragment>
      ))}
    </Layout>
  )
}

export default MasterStaticLanding

export const Head = ({ data }) => {
  const pageData = data?.strapiPage
  const images = pageData?.ggfx_results
  const findImageBySize = (size) => {
    if (!Array.isArray(images)) {
      // If images is not an array, return null or handle the error accordingly
      return null;
    }
  
    for (let image of images) {
      if (!image?.transforms || image?.transforms?.length === 0) continue;
        for (let transform of image.transforms) {
            if (transform?.transform === size) {
                return transform?.url; // Return the URL if the size matches
            }
        }
    }
    return null; // Return null if no image with the desired size is found
};

const imageUrl = findImageBySize("744x");
  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
      img={imageUrl ? imageUrl : pageData?.banner?.banner_image?.url}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_ADS_BANNER {
          ...adsBanner
        }
        ... on STRAPI__COMPONENT_MODULES_GLOBAL_MODULE {
          ...globalModule
        }
        ... on STRAPI__COMPONENT_MODULES_TIMELINE {
          ...timelineFragment
        }
        ... on STRAPI__COMPONENT_MODULES_FEATURED_PROP_SLIDER {
          ...featuredProperties
        }
        ... on STRAPI__COMPONENT_MODULES_CONTENT_AND_STATS {
          ...contentAndStats
        }
          ... on STRAPI__COMPONENT_MODULES_YEAR_AWARD {
          ...yearAward
        }
        ... on STRAPI__COMPONENT_MODULES_FAQ {
          ...faqModule
        }
        ... on STRAPI__COMPONENT_MODULES_TILE_BLOCK {
          ...tileBlock
        }
        ... on STRAPI__COMPONENT_MODULES_OUR_SERVICES {
          ...ourServices
        }
        ... on STRAPI__COMPONENT_MODULES_REVIEW_BANNER {
          ...reviewBanner
        }
        ... on STRAPI__COMPONENT_MODULES_STAFF_THOUGHT {
          ...staffThought
        }
        ... on STRAPI__COMPONENT_MODULES_IMAGES_SLIDER {
          ...imgSlider
        }
        ... on STRAPI__COMPONENT_MODULES_CAREER_LISTING {
          ...careerListing
        }
        ... on STRAPI__COMPONENT_COMPONENTS_TEXT_RICH_TEXT {
          ...textRichText
        }
        ... on STRAPI__COMPONENT_MODULES_FORM_MODULE {
          ...FormModuleFragment
        }
        ... on STRAPI__COMPONENT_MODULES_IFX_CALCULATOR {
          ...ifxCalculator
        }
        ... on STRAPI__COMPONENT_COMPONENTS_VIDEO_BLOCK {
          ...videoBlockFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_PROJECTS_SLIDER {
          ...projectsSliderFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_PROJECTS_LISTING {
          ...projectsListingFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_IMAGE_BANNER {
          ...ImageBannerFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_INTERIOR_PROJECT {
          ...interiorProjectSliderFragment
        }
        ... on STRAPI__COMPONENT_MODULES_PARTNER {
          ...partner
        }
        ... on STRAPI__COMPONENT_MODULES_ICON_CARDS {
          ...iconCardsFragment
        }
        ... on STRAPI__COMPONENT_MODULES_QUESTIONNAIRE {
          ...questionnaireFragment
        }
        ... on STRAPI__COMPONENT_MODULES_IMAGE_SLIDER {
          ...imageSliderModule
        }
        ... on STRAPI__COMPONENT_MODULES_QUOTE {
          ...quoteModuleFragment
        }
          
      }
    }
  }
`
