import OffplanCard from "@components/OffplanCard/OffplanCard"
import Animation from "@components/elements/Animation"
import Slider from "@components/elements/Slider/Slider"
import useLimitReadyOffplans from "@hooks/dataHooks/useLimitReadyOffplans"
import React, { useEffect, useMemo, useState } from "react"
import { Container } from "react-bootstrap"
import "./ProjectsSlider.scss"
import { getOrderedImages } from "@lib/utils"
const ProjectsSlider = ({ module }) => {
  const { offplans: offplansToShow } = useLimitReadyOffplans(module?.select_type)

  if (!module) return null
  const { title } = module

  let padding = ""
  if (module?.spacing === "none") {
    padding = "m-0"
  } else if (module?.spacing === "top_none") {
    padding = "mt-0"
  } else if (module?.spacing === "bottom_none") {
    padding = "mb-0"
  }

  return (
    <Animation
      animateInType="up"
      className={`projects-slider-module-wrap section-m section-p ${padding}`}
    >
      <Container className="area-guide-featured-slider-module-container">
        <div className="content-section">
          <div className="d-flex justify-content-between">
            <h2 className="title">
              <span>{title}</span>
            </h2>
          </div>
        </div>
        <div className="area-guide-featured-slider-tab-section">
          <div className="tab-body">
            <Slider size="sm">
              {offplansToShow
              .slice(0, 6)
              .map((offplan) => {
                const imagesArray = getOrderedImages(offplan);
                return(
                  <OffplanCard
                  minsBeds={offplan.min_bedrooms}
                  maxBeds={offplan.max_bedrooms}
                  displayBedrooms={offplan.display_bedrooms}
                  buldingType={offplan.building_type}
                  image={offplan.tile_image ? offplan.tile_image : offplan?.media_images?.length > 0 ? offplan?.media_images[0] : offplan?.images?.length > 0 ? offplan?.images[0] : ""}
                  images2={imagesArray}
                  title={offplan.title}
                  status={offplan.status}
                  developer={offplan.developer}
                  location={offplan.display_address}
                  slug={offplan.slug}
                  videoURL={offplan?.video_module?.video_url}
                  community={offplan?.community?.title}
                  key={offplan.id}
                  completionYear={offplan.completion_year}
                  price={offplan?.price}
                  slider
                  future_launch={offplan?.future_launch}
                  ggfx_results={offplan?.ggfx_results}
                  strapi_id={offplan?.strapi_id}
                  notalgo
                  property={offplan}
                />
                )
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </Animation>
  )
}

export default ProjectsSlider
